import {
  APP_PREFIX_PATH,
  AUTHENTICATED_ENTRY,
  AUTH_PREFIX_PATH,
  HOME_ENTRY,
  UNAUTHENTICATED_ENTRY
} from "configs/AppConfig";
import { homeRoutes, protectedRoutes, publicRoutes } from "configs/RoutesConfig";
import { useIdleTimer } from "react-idle-timer";
import { Navigate, Route, Routes as RouterRoutes, useNavigate } from "react-router-dom";
import AppRoute from "./AppRoute";
import ProtectedRoute from "./ProtectedRoute";
import PublicRoute from "./PublicRoute";
import { useSelector } from "react-redux";
import { useCallback } from "react";

const Routes = () => {
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleOnIdle = useCallback(
    (event) => {
      if (!token) return;
      const pathname = window.location.toString();
      // console.log(pathname.split("/")[3])
      if (pathname.split("/")[3] !== "app") return;
      console.log("user is idle", event);
      console.log("last active", getTotalIdleTime());
      localStorage.setItem("LOCKSCREEN", getTotalIdleTime());
      navigate("/auth/lock-screen");
      // window.location.replace("/auth/lock-screen");
    },
    [token]
  );
  const handleOnActive = (event) => {
    //console.log('user is active', event)
    //console.log('time remaining', getRemainingTime())
  };
  const handleOnAction = (event) => {
    // console.log('user did something', event)
  };
  const { getTotalIdleTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
  });

  return (
    <RouterRoutes>
      <Route path={"/"} element={<ProtectedRoute />}>
        {protectedRoutes.map((route, index) => {
          return (
            <Route
              key={route.key + index}
              path={route.path}
              element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
            />
          );
        })}
        <Route path={"/"} element={<Navigate to={UNAUTHENTICATED_ENTRY} replace />} />
      </Route>
      <Route path={"/"} element={<PublicRoute />}>
        {publicRoutes.map((route) => {
          return (
            <Route
              key={route.path}
              path={route.path}
              element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
            />
          );
        })}
        <Route path={AUTH_PREFIX_PATH + "/*"} element={<Navigate to={UNAUTHENTICATED_ENTRY} replace />} />
      </Route>
      {homeRoutes.map((route, index) => (
        <Route
          key={route.key + index}
          path={route.path}
          element={<AppRoute routeKey={route.key} component={route.component} {...route.meta} />}
        />
      ))}
      <Route path={"*"} element={<Navigate to={HOME_ENTRY} replace />} />
    </RouterRoutes>
  );
};

export default Routes;
