export const BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER_URL;
export const GET_PRESIGNED_URL = "/api/upload/presigned-url/";
export const GET_SIGNED_URL = "/api/upload/signed-url/";
export const GET_USER_DTLS_URL = "/api/user/dtl/";
export const GET_ORGIDMAP_URL = "/api/orgidmap/";
export const GET_ORGDTLS_URL = "/api/orgdtls/";
export const GET_OBR_MAPPING = "/api/obrmapping/";
export const GET_SMS_TEMPLATE = "/api/smstemplate/";
export const GET_LOCATION_COMBO_URL = "/api/location/combo/";
export const UPDATE_FAXSERVER_URL = "/api/faxserver/save";
export const UPDATE_FAXSERVER_URL_WITH_AI = "/api/faxserver/save_with_ai";
export const GET_LBINS_URL = "/api/locationbins/";
export const GET_INCOMINGREQUEST_URL = "/api/faxserver/incoming/";
export const GET_INCOMINGREQUEST_URL_LAST_EVAL_KEY = "/api/faxserver/incoming_last_eval_key/";
export const GET_INCOMINGREQUEST_COMPLETED_URL = "/api/faxserver/incoming_completed/";
export const SAVE_AUDIT_TRAIL_URL = "/api/audittrail/";
export const SAVE_USERDTLS_URL = "/api/user/save";
export const GET_LOCATION_URL = "/api/location/";
export const GET_FAXSERVER_URL = "/api/FaxServer/";
export const SAVE_ACCDTLS_URL = "/api/accDtls/save/";
export const DEL_ACCDTLS_URL = "/api/accDtls/";
export const SAVE_ORGDTLS_URL = "/api/orgdtls/save/";
export const SAVE_LBINS_URL = "/api/locationbins/";
export const DELETE_LBINS_URL = "/api/locationbins/";
export const SAVE_SMS_HISTORY = "/api/smshistory/";
export const GET_SMS_HISTORY = "/api/smshistory/";
export const GET_CONTACTS_URL = "/api/contact/";
export const SAVE_CONTACTS_URL = "/api/contact/";
export const DELETE_CONTACTS_URL = "/api/contact/";
export const SEARCH_CONTACTS_URL = "/api/contact/";
export const AUTOCOMPLETE_CONTACTS_URL = "/api/contact/autocomplete/";
export const GET_TEXTRACT_URL = "/api/textextract/";
export const GET_USER_LIST_URL = "/api/user/list/";
export const POST_NEW_USER_URL = "/api/user/createNewUser";
export const PUT_USER_RESET_PASSWORD_URL = "/api/user/reset_password";
export const SAVE_TEXTEXTRACT_URL = "/api/textextract/";
export const SEARCH_PAGE_URL = "/api/search/";
export const SAVE_ORGIDMAP_URL = "/api/orgidmap/save/";
export const CREATE_SMS_TEMPLATE = "/api/smstemplate/";
export const SAVE_SMS_TEMPLATE = "/api/smstemplate/update/";
export const GET_ADMIN_ORGANIZATION = "/api/organization/";
export const CREATE_ORGANIZATION = "/api/organization/";
export const SAVE_CHECKIN_REQ = "/api/checkin/save/";
export const GET_CHECKIN_REQ = "/api/checkin/req/";
export const GET_CHECKIN_COMPLETED = "/api/checkin/completed/";
export const DEL_ORG_ID_MAP = "/api/orgidmap/";
export const GET_TOP_USER = "/api/user/top/";
export const GET_NEW_LOC = "/api/location/newloc/";
export const GET_URGENT_LOC = "/api/location/urgentloc/";
export const GET_CURRENT_STAT = "/api/stat/";
export const GET_NESTED_TOP_USER = "/api/user/nested-top/";
export const GET_AVERAGE = "/api/average/";
export const SAVE_USER_DTLS = "/api/user/save/";
export const SAVE_OBR_MAPPING = "/api/obrmapping/";
export const VALIDATE_HEALTHCARD = "/api/patient_upload/validateHealthCard";
export const PHONE_VALIDATOR = "/api/phone/validator/";
export const CONVERT_PDF_TO_IMAGE = "/api/pdfconvert";
export const PATIENT_UPLOAD_URL = "/api/patient_upload/";
export const AUTOCOMPLETE_LOCATIONS_URL = '/api/location/autocomplete/';
export const UPDATE_FAXSERVER_S3_URL = '/api/faxserver/updateS3Url/';
export const CREATE_LOCATION = '/api/location/';
export const GET_PATIENT_FAX = '/api/faxserver/patient/';
export const GET_PDF_EXTRACT_CONFIG = '/api/pdfextract/';
export const GET_PHYSICIAN_REQ = '/api/physician/';
export const GET_PHYSICIAN_SEARCH_FAX = '/api/physician/search-fax/';
export const CREDITS_TRACKING = '/api/creditstracking/';
export const GET_ALL_ORG = '/api/organization/all/';
export const GET_ALL_DAILY = '/api/daily/';
export const CREATE_CHECKIN =  '/api/checkin/';
export const USER_REQ = '/api/user/req/';
export const GET_ALL_USERS = '/api/user/all';
export const SAVE_REGISTER = '/api/register';
export const GET_MODALITY = '/api/modality/';
export const SAVE_KEYWORD = '/api/keyword/save';
export const GET_KEYWORD = '/api/keyword/';
export const DELETE_KEYWORD = '/api/keyword/';
export const GET_PDF_TEMPLATE = '/api/pdftemplate/';
export const SAVE_PDF_TEMPLATE = '/api/pdftemplate/';
export const SAVE_PDF_EXTRACTION_TEMPLATE = '/api/pdftemplate/extraction';
export const SAVE_PHONE = '/api/phone';
export const SAVE_SUBSCRIPTION = '/api/usersubscription';
export const UNSUBSCRIBE = '/api/usersubscription/unsubscribe/';
export const DEMO_FAX_VELOX_SAVE = '/api/demoFaxVelox';
export const SEND_EMAIL = '/api/usersubscription/send-email';
export const TELYNX_API_PHONE_NUMBERS = 'https://api.telnyx.com/v2/phone_numbers';
export const TELYNX_API_GET_LIST_AVAILABLE_PHONE_NUMBERS = 'https://api.telnyx.com/v2/available_phone_numbers/';
export const TELYNX_API_CREATE_NUMBERS_ORDER = 'https://api.telnyx.com/v2/number_orders';
export const TELYNX_API_FAX_APPLICATION = 'https://api.telnyx.com/v2/fax_applications';
export const VELOX_CREAT_REFERRAL = '/api/velox/createReferral';
export const VELOX_GET_APPOINTMENTS = '/api/velox/getAppointments';
export const VELOX_GET_SERVICETYPES = '/api/velox/getServiceTypes/';
export const VELOX_GET_CLINICS = '/api/velox/getClinics/';