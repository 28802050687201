import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { supabase } from "auth/SupabaseClient";
import fetchWrapper from "services/api/fetchWrapper";
import * as APP_CONFIG from 'configs/Config';

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token: localStorage.getItem("AUTH_TOKEN") || null,
};

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (credentials, { rejectWithValue }) => {
  const { email, password } = credentials;

  try {
    const { data, error } = await supabase.auth.signInWithPassword({ email, password });

    if (error) {
        if (error.code === 'invalid_credentials') {
          return rejectWithValue('Invalid login credentials');
      }
        return rejectWithValue(error.message || 'Login failed');
    }

    const token = data.session.access_token;
    const userId = data.user.id;

      // Set data to localStorage
      localStorage.setItem("USER_ID", userId);
      localStorage.setItem("AUTH_TOKEN", token);
      localStorage.removeItem('LOCKSCREEN');

    try {
      const userDetailsResponse = await fetchWrapper(`${APP_CONFIG.GET_USER_DTLS_URL}${userId}`);
      const userDtls = await userDetailsResponse.json();
        localStorage.setItem('USER_DETAILS', JSON.stringify(userDtls));

      const orgIdMapResponse = await fetchWrapper(`${APP_CONFIG.GET_ORGIDMAP_URL}${userId}`);
      const mapDetails = await orgIdMapResponse.json();

      if (mapDetails.length > 0) {
          localStorage.setItem('MAP_DETAILS', JSON.stringify(mapDetails));

        const organizationId = mapDetails[0].Organization_ID;
        const orgDetailsResponse = await fetchWrapper(`${APP_CONFIG.GET_ORGDTLS_URL}${organizationId}`);
        const orgDtls = await orgDetailsResponse.json();

          localStorage.setItem('ORG_DETAILS',JSON.stringify({telnyx_from: orgDtls.telnyx_from}));
      } else {
          const defaultMap = [{ Organization_ID: '1' }];
          localStorage.setItem('MAP_DETAILS', JSON.stringify(defaultMap));
      }
    } catch (error) {
        console.error('Error fetching user or organization details:', error);
        return rejectWithValue('Account does not exist');
    }

    return token;
  } catch (error) {
      console.error('Error during sign in:', error);
      return rejectWithValue(error.message || 'Error');
  }
  },
);
export const signUp = createAsyncThunk(
  'auth/signUp',
  async (data, { rejectWithValue }) => {
  const { email, password } = data;

  try {
    const { data: signUpData, error: signUpError } = await supabase.auth.signUp({ email, password });

    if (signUpError) {
        console.error('Sign up error:', signUpError);
        return rejectWithValue(signUpError.message || 'Sign up failed');
    }

      if (signUpData.user?.identities?.length === 0)
        return rejectWithValue('User already registered');

    if (signUpData && signUpData.user) {
      const userId = signUpData.user.id;
      localStorage.setItem("USER_ID", userId);
      return userId;
    } else {
        return rejectWithValue('Sign up failed: No user data returned');
    }
  } catch (err) {
      console.error('Unexpected error during sign up:', err);
      return rejectWithValue(err.message || 'An unexpected error occurred');
    }
  }
);

export const signOut = createAsyncThunk('auth/signOut', async (_, { rejectWithValue }) => {
  try {
    const { error } = await supabase.auth.signOut();

    if (error) {
      console.error('Sign out error:', error);
      return rejectWithValue(error.message || 'Sign out failed');
    }
    localStorage.removeItem("USER_ID");
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem('LOCK_SCREEN');
    localStorage.removeItem('USER_DETAILS');
    localStorage.removeItem('MAP_DETAILS');
    localStorage.removeItem('ORG_DETAILS');
    localStorage.removeItem('CURRENT_LOCATION');

    window.location.replace('/auth/login');

    return 'Sign out successful';
  } catch (err) {
    console.error('Unexpected error during sign out:', err);
    return rejectWithValue(err.message || 'An unexpected error occurred');
  }
});

export const signInWithGoogle = createAsyncThunk(
  'auth/signInWithGoogle',
  async (_, { rejectWithValue }) => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
    });

    if (error) {
        console.error('Google sign-in error:', error);
        return rejectWithValue(error.message || 'Google sign-in failed');
    }

    if (data && data.user) {
      const userId = data.user.id;
      localStorage.setItem("USER_ID", userId);
      return userId;
    } else {
        return rejectWithValue('Google sign-in failed: No user data returned');
    }
  } catch (err) {
      console.error('Unexpected error during Google sign-in:', err);
      return rejectWithValue(err.message || 'An unexpected error occurred');
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  'auth/signInWithFacebook',
  async (_, { rejectWithValue }) => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
        provider: 'facebook',
    });

    if (error) {
        console.error('Facebook sign-in error:', error);
        return rejectWithValue(error.message || 'Facebook sign-in failed');
    }

    if (data && data.user) {
      const userId = data.user.id;
      localStorage.setItem("USER_ID", userId);
      return userId;
    } else {
        return rejectWithValue('Facebook sign-in failed: No user data returned');
    }
  } catch (err) {
      console.error('Unexpected error during Facebook sign-in:', err);
      return rejectWithValue(err.message || 'An unexpected error occurred');
    }
  }
);
export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  }
});

export const { authenticated, showAuthMessage, hideAuthMessage, signOutSuccess, showLoading, signInSuccess } = authSlice.actions;

export default authSlice.reducer;
