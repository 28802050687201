import * as APP_CONFIG from 'configs/Config';

const getToken = () => {
  return localStorage.getItem("AUTH_TOKEN");
};

const fetchWrapper = async (endpoint, options = {}) => {
    const token = getToken();
    const flag = endpoint.startsWith('/api/');
    const fullUrl = flag ? `${APP_CONFIG.BACKEND_SERVER}${endpoint}` : endpoint;

    const headers = {
        ...options.headers,
    };

    if (flag) {
        headers['Content-Type'] = 'application/json';
        if (token) {
            headers['Authorization'] = `Bearer ${token}`;
        }
    }

    const fetchOptions = {
        ...options,
        headers,
    };

    try {
        const response = await fetch(fullUrl, fetchOptions);

        if (!response.ok && flag) {
            if (response.status === 401 || response.status === 403) {
                localStorage.removeItem("AUTH_TOKEN");
                localStorage.removeItem("USER_ID");
                localStorage.removeItem("LOCKSCREEN");
                localStorage.removeItem("USER_DETAILS");
                localStorage.removeItem("MAP_DETAILS");
                localStorage.removeItem("ORG_DETAILS");
                window.location.replace("/auth/login");
            }
        }

        return response;
    } catch (error) {
        console.error('Fetch error:', error);
    }
};

export default fetchWrapper;
