import { AUTH_PREFIX_PATH, REDIRECT_URL_KEY, UNAUTHENTICATED_ENTRY, EXPIRED_PASSWORD_ENTRY } from "configs/AppConfig";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  const location = useLocation();
  const lockedValue = localStorage.getItem("LOCKSCREEN");
  const getSupabaseAuthTokenKey = () => {
    const supabaseUrl = new URL(process.env.REACT_APP_SUPABASE_URL);
    const supabaseHostKey = supabaseUrl.host.split(".")[0];
    return `sb-${supabaseHostKey}-auth-token`;
  };
  
  const supabaseAuthTokenKey = getSupabaseAuthTokenKey();

  let supabaseAuth = JSON.parse(localStorage.getItem(supabaseAuthTokenKey)) || null;

  const getDateNDaysAgo = (days) => {
    const date = new Date();
    date.setDate(date.getDate() - days);
    return date;
  };

  const date60DaysAgo = getDateNDaysAgo(60);
  const isPasswordExpired = supabaseAuth ? new Date(supabaseAuth?.user.user_metadata.password_last_updated_at) < date60DaysAgo : false;
  if (!token) {
    return (
      <Navigate to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />
    );
  } else if (lockedValue) {
    return <Navigate to={`${AUTH_PREFIX_PATH}/lock-screen?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
  } else if (isPasswordExpired && location.pathname !== EXPIRED_PASSWORD_ENTRY) {
    return <Navigate to={`${EXPIRED_PASSWORD_ENTRY}?${REDIRECT_URL_KEY}=${location.pathname}`} replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;